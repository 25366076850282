import React from "react";

import classNames from "classnames";

import { TypographyProps } from "@mui/material";

import { TypographyWrapper } from "./styled";

export type TVariants = "h0" | "h1" | "h2" | "h3" | "h4" | "p0" | "p1" | "p2" | "p3" | "p4";

export interface MyTypographyProps extends TypographyProps {
    className?: string;
    size?: number;
    variant?: TVariants;
    text?: string;
    fontWeight?: "bold" | "medium" | "regular";
    color?: string;
}

export function Typography(props: MyTypographyProps) {
    const { className, variant = "h1", color, children, ...rest } = props;

    return (
        <TypographyWrapper
            data-test="bi-button"
            className={classNames(variant, "inm-typography typography", className)}
            variant={variant}
            color={color}
            {...rest}
        >
            {children}
        </TypographyWrapper>
    );
}
