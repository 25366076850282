import styled from "styled-components";

export const HeaderWrapper = styled.header`
    position: sticky;
    display: flex;
    transition: top 0.3s;
    z-index: 999;
    justify-content: space-between;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(14)};
    ${({ theme }) => theme.breakpoints.down("xl")} {
        padding: ${({ theme }) => theme.spacing(12, 10)};
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
        padding: ${({ theme }) => theme.spacing(8)};
    }
    ${({ theme }) => theme.breakpoints.down("s")} {
        padding: ${({ theme }) => theme.spacing(6)};
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
        padding: ${({ theme }) => theme.spacing(5)};
    }
    ${({ theme }) => theme.breakpoints.down("xs")} {
        padding: ${({ theme }) => theme.spacing(5, 4)};
    }
`;
