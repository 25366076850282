import styled from "styled-components";

export const CirclesWrapper = styled.div`
    .circle {
        position: absolute;
        z-index: 1;

        img {
            position: absolute;
            filter: blur(10px);
            border-radius: 50%;
        }
    }
    .circle-0 {
        width: 200px;
        top: 80px;
        left: 600px;
        animation: spin-right 24s linear infinite;

        img {
            width: 200px;
        }

        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 220px;
            top: 80px;
            left: 600px;
            img {
                width: 120px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("lg")} {
            top: 120px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 98px;
            top: 130px;
            left: 475px;
            img {
                width: 98px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 75px;
            top: 345px;
            left: 375px;

            img {
                width: 75px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            top: 250px;
            left: 230px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            top: 230px;
            left: 120px;
        }
    }
    .circle-1 {
        width: 230px;
        top: 310px;
        right: 330px;
        animation: spin-left 24s linear infinite;

        img {
            width: 230px;
        }

        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 240px;
            top: 200px;
            right: 400px;
            img {
                width: 140px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("xl")} {
            right: 120px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 114px;
            top: 400px;
            right: 150px;
            img {
                width: 114px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 87px;
            top: 250px;
            right: 130px;
            img {
                width: 87px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            top: 400px;
            left: 455px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            top: 450px;
            left: 400px;
        }
    }
    .circle-2 {
        width: 340px;
        top: 900px;
        left: 400px;
        animation: spin-right 20s linear infinite;

        img {
            width: 340px;
        }

        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 206px;
            top: 530px;
            left: 400px;

            img {
                width: 206px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("lg")} {
            top: 480px;
            left: 300px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 167px;
            top: 380px;
            left: 210px;

            img {
                width: 167px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 127px;
            top: 480px;
            left: 200px;

            img {
                width: 127px;
            }
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            top: 500px;
            left: 100px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            top: 650px;
            left: 100px;
        }
    }

    @keyframes spin-left {
        from {
            transform: rotate(0deg) translateX(100px) rotate(0deg);
        }
        to {
            transform: rotate(-360deg) translateX(100px) rotate(360deg);
        }
    }

    @keyframes spin-right {
        from {
            transform: rotate(0deg) translateX(100px) rotate(0deg);
        }
        to {
            transform: rotate(360deg) translateX(100px) rotate(-360deg);
        }
    }
`;
