import React from "react";
import { useNavigate } from "react-router-dom";

import { SvgIcon } from "@mui/material";

import { Logo } from "../../../assets";
import { HeaderLogoWrapper } from "./styled";

export function HeaderLogo() {
    const navigate = useNavigate();

    return (
        <HeaderLogoWrapper data-testid="header-logo-wrapper">
            <SvgIcon
                className="header-logo"
                component={Logo}
                inheritViewBox
                onClick={() => {
                    navigate("/");
                }}
            />
        </HeaderLogoWrapper>
    );
}
