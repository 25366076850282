import React from "react";

import {
    AboutPage,
    ContactUs,
    CareersPage,
    ExamplePage,
    HomePage,
    Projects,
    SelectedProject,
    NotFound,
} from "../pages";
import * as Paths from "./Paths";

interface IRoute {
    [key: string]: {
        path: string;
        Element: React.ElementType;
    };
}

export const APP_ROUTES: IRoute = {
    about: {
        path: Paths.ABOUT,
        Element: AboutPage,
    },
    careers: {
        path: Paths.CAREERS,
        Element: CareersPage,
    },
    contactUs: {
        path: Paths.CONTACT_US,
        Element: ContactUs,
    },
    example: {
        path: Paths.EXAMPLE,
        Element: ExamplePage,
    },
    home: {
        path: Paths.HOME,
        Element: HomePage,
    },
    projects: {
        path: Paths.PROJECTS,
        Element: Projects,
    },
    project: {
        path: Paths.SELECTED_PROJECT,
        Element: SelectedProject,
    },
    notFound: {
        path: Paths.NOT_FOUND,
        Element: NotFound,
    },
} as const;
