import styled from "styled-components";

import { Typography } from "@mui/material";

import { MyTypographyProps } from ".";

export const TypographyWrapper = styled(Typography)<Partial<MyTypographyProps>>`
    && {
        color: ${({ theme, color }) => color || theme.palette.primary.main};
        &.h0 {
            font-family: "Newake";
            line-height: 1.3;
            font-size: 480px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 420px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 380px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 300px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 260px;
            }
        }
        &.h1 {
            font-family: "Newake";
            line-height: 1.3;
            font-size: 360px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 260px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 200px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 140px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 86px;
            }
        }
        &.h2 {
            font-family: "Newake";
            line-height: 1.3;
            font-size: 160px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 120px;
            }
            ${({ theme }) => theme.breakpoints.down("lg")} {
                font-size: 100px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 88px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 64px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 48px;
            }
        }
        &.h3 {
            font-family: "Newake";
            line-height: 1.3;
            font-size: 136px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 100px;
            }
            ${({ theme }) => theme.breakpoints.down("lg")} {
                font-size: 80px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 72px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 50px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 32px;
            }
        }
        &.h4 {
            font-family: "Newake";
            line-height: 1.3;
            font-size: 80px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 60px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 56px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 40px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 28px;
                line-height: 1.4;
            }
        }
        &.p0 {
            font-family: "Helvetica Neue Medium";
            line-height: 1.3;
            font-size: 48px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 40px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 28px;
                line-height: 1.4;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 22px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 20px;
            }
        }
        &.p1 {
            font-family: "Helvetica Neue Medium";
            line-height: 1.3;
            font-size: 40px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 32px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 24px;
                line-height: 1.4;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 20px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 18px;
            }
        }
        &.p2 {
            font-family: "Helvetica Neue Medium";
            line-height: 1.3;
            font-size: 32px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 24px;
                line-height: 1.4;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 20px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 18px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 16px;
            }
        }
        &.p3 {
            font-family: "Helvetica Neue Medium";
            line-height: 1.4;
            font-size: 26px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 20px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                font-size: 18px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 16px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 14px;
            }
        }
        &.p4 {
            font-family: "Helvetica Neue Medium";
            line-height: 1.4;
            font-size: 20px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                font-size: 16px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                font-size: 14px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                font-size: 12px;
            }
        }
    }
`;
