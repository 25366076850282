export type IPalette = {
    [key: string]: {
        [key: string]: string;
    };
};

export default {
    primary: {
        main: "#ffffff",
        orange: "#ff6e00",
    },
    secondary: {
        main: "#898777",
        oldLace: "#F9F7E8",
        blue: "#2BD9E0",
    },
    background: {
        black: "#000000",
    },
    error: {
        main: "#EF5757",
    },
    other: {
        yellow: "#FFC200",
        footerLogo: "#292823",
        black: "#000000",
    },
} as IPalette;
