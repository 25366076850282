import { lazy } from "react";

const AboutPage = lazy(() => import(/* webpackChunkName: "pages/About" */ "./About"));
const HomePage = lazy(() => import(/* webpackChunkName: "pages/Main" */ "./Home"));
const ExamplePage = lazy(() => import(/* webpackChunkName: "pages/ExamplePage" */ "./ExamplePage"));
const CareersPage = lazy(() => import(/* webpackChunkName: "pages/Careers" */ "./Careers"));
const ContactUs = lazy(() => import(/* webpackChunkName: "pages/ContactUs" */ "./ContactUs"));
const Projects = lazy(() => import(/* webpackChunkName: "pages/Projects" */ "./Projects"));
const SelectedProject = lazy(
    () => import(/* webpackChunkName: "pages/Projects/SelectedProject" */ "./Projects/SelectedProject")
);
const NotFound = lazy(() => import(/* webpackChunkName: "pages/NotFound" */ "./NotFound"));

export { AboutPage, ContactUs, CareersPage, ExamplePage, HomePage, Projects, SelectedProject, NotFound };
