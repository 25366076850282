export interface ICareerItemRequirement {
    title: string;
    list: string[];
}
export interface ICareerItem {
    type: string;
    title: string;
    description: string;
    requirements: ICareerItemRequirement[];
}

export interface ICareersList {
    position: string;
    jobType: string;
    jobVariant: string;
    id: number;
    data: ICareerItem;
}

export const OPEN_POSITIONS: ICareersList[] = [
    {
        position: "Senior React.JS Engineer",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 0,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
    {
        position: "Mid Manual QA",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 1,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
    {
        position: "Senior Node.JS Engineer",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 2,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
    {
        position: "Senior Automation QA",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 3,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
    {
        position: "Senior Full Stack Engineer",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 4,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
    {
        position: "Principal Frontend Engineer",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 5,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
    {
        position: "Tech Lead",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 6,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
    {
        position: "Senior C# Engineer",
        jobType: "full-Time",
        jobVariant: "in Office",
        id: 7,
        data: {
            type: "Engineer",
            title: "General Description and Purpose",
            description:
                "This position is responsible for understanding business requirements and transforming them into technical requirements. Individuals in this role must oversee all aspects of software and product requirements within the company and ensure the smooth running of the developer department. The role holder position works collaboratively with QAs, designers, and management.",
            requirements: [
                {
                    title: "Essential Duties",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Minimum Requirements",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
                {
                    title: "Company Benefits",
                    list: [
                        "Ensure the reliability of both quality and organization, deadlines",
                        "Learn to ship new features and improve every day",
                        "Research and analyze existing systems and applications requirements",
                    ],
                },
            ],
        },
    },
];
