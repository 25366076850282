import React from "react";

import { IconNames } from "common";

import { SvgIcon } from "@mui/material";

import * as AllIcons from "../../icons";

export interface IconProps {
    icon: IconNames;
    className?: string;
    color?: string;
    height?: number;
    size?: number;
    viewBox?: string;
    onClick?: () => void;
    inheritViewBox?: boolean;
}

export function Icon(props: IconProps) {
    const { color, className, icon, height, size = 24, viewBox = "0 0 24 24", inheritViewBox, onClick } = props;

    return (
        <SvgIcon
            data-test="bi-icon"
            style={{
                color,
                fill: "currentColor",
                width: `${size}px`,
                height: `${height || size}px`,
                fontSize: `${size}px`,
                lineHeight: `${size}px`,
            }}
            className={className}
            component={AllIcons[icon] as React.ElementType}
            viewBox={viewBox}
            onClick={onClick}
            inheritViewBox={inheritViewBox}
        />
    );
}
