import styled from "styled-components";

export const LoaderWrapper = styled.div`
    z-index: ${({ theme }) => theme.zIndex.tooltip};
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.other.black};

    &.animation {
        animation-duration: 1s;
        animation-delay: 1s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-name: right-to-left;
    }

    .black-animation {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1501;
        background-color: ${({ theme }) => theme.palette.other.black};
        animation-duration: 0.1s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-name: right-to-left-black;
    }

    .loader {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: ${({ theme }) => theme.zIndex.mobileStepper};
        display: flex;
        justify-content: center;
        align-items: center;

        .loader-digit {
            width: 232px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                width: 166px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                width: 128px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                width: 102px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                width: 56px;
            }
        }
    }

    @keyframes right-to-left {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100vw, 0);
        }
    }
    @keyframes right-to-left-black {
        0% {
            transform: translate(100%, 0);
        }
        100% {
            transform: translate(0, 0);
        }
    }
`;
