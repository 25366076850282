import styled from "styled-components";

export const FooterStyles = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding: ${({ theme }) => theme.spacing(14)};
    margin-top: ${({ theme }) => theme.spacing(85)};
    ${({ theme }) => theme.breakpoints.down("xl")} {
        padding: ${({ theme }) => theme.spacing(10)};
        margin-top: ${({ theme }) => theme.spacing(60)};
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
        margin-top: ${({ theme }) => theme.spacing(50)};
    }
    ${({ theme }) => theme.breakpoints.down("s")} {
        padding: ${({ theme }) => theme.spacing(6)};
        margin-top: ${({ theme }) => theme.spacing(45)};
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
        padding: ${({ theme }) => theme.spacing(5)};
    }
    ${({ theme }) => theme.breakpoints.down("xs")} {
        padding: ${({ theme }) => theme.spacing(4)};
        margin-top: ${({ theme }) => theme.spacing(40)};
    }
    .footer-left-section {
        max-width: 60%;
        display: flex;
        flex-direction: column;
        position: absolute;
        ${({ theme }) => theme.breakpoints.down("sm")} {
            top: 46px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            top: 232px;
            min-width: 100%;
            padding-bottom: ${({ theme }) => theme.spacing(4)};
            padding-right: ${({ theme }) => theme.spacing(8)};
        }
    }
    .footer-social-info {
        color: ${({ theme }) => theme.palette.primary.main};
        display: flex;
        flex-direction: column;
        text-align: right;
        .social-icons {
            padding-top: ${({ theme }) => theme.spacing(21)};
            ${({ theme }) => theme.breakpoints.down("xl")} {
                padding-top: ${({ theme }) => theme.spacing(16)};
            }
            ${({ theme }) => theme.breakpoints.down("lg")} {
                padding-top: ${({ theme }) => theme.spacing(15)};
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                padding-top: ${({ theme }) => theme.spacing(12)};
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                padding-top: ${({ theme }) => theme.spacing(8)};
            }
            .social-icon {
                svg {
                    transition: all ease 0.4s;
                    width: 40px !important;
                    height: 40px !important;
                    margin-left: ${({ theme }) => theme.spacing(6)};
                    color: ${({ theme }) => theme.palette.primary.main};
                    ${({ theme }) => theme.breakpoints.down("xl")} {
                        width: 32px !important;
                        height: 32px !important;
                        margin-left: ${({ theme }) => theme.spacing(4)};
                    }
                    ${({ theme }) => theme.breakpoints.down("md")} {
                        width: 28px !important;
                        height: 28px !important;
                    }
                    ${({ theme }) => theme.breakpoints.down("s")} {
                        width: 24px !important;
                        height: 24px !important;
                    }
                }
                &:hover {
                    svg {
                        color: ${({ theme }) => theme.palette.secondary.blue};
                    }
                }
            }
        }
        .footer-email {
            color: ${({ theme }) => theme.palette.primary.main};
            margin-left: auto;
            padding-top: ${({ theme }) => theme.spacing(12)};
            ${({ theme }) => theme.breakpoints.down("xl")} {
                padding-top: ${({ theme }) => theme.spacing(10)};
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                padding-top: ${({ theme }) => theme.spacing(8)};
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                padding-top: ${({ theme }) => theme.spacing(6)};
            }
            p {
                text-transform: lowercase !important;
            }
        }
    }
    .footer-logo {
        color: ${({ theme }) => theme.palette.other.footerLogo};
        width: 1398px;
        height: 256px;
        margin-bottom: ${({ theme }) => theme.spacing(8)};
        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 1082px;
            height: 197px;
        
        ${({ theme }) => theme.breakpoints.down("lg")} {
            width: 1061px;
            height: 194px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 864px;
            height: 158px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 546px;
            height: 100px;
            margin-bottom: ${({ theme }) => theme.spacing(16)};
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            width: 480px;
            height: 88px;
            margin-bottom: ${({ theme }) => theme.spacing(7)};
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            width: 100%;
        }
    }
`;
