export const TextWidth = {
    xxl: 310,
    xl: 775,
    lg: 675,
    md: 500,
    s: 365,
    sm: 340,
    xs: 255,
};

export const LeftMargin = {
    xxl: 310,
    xl: 250,
    lg: 170,
    md: 200,
    s: 145,
    sm: 70,
    xs: 0,
};
