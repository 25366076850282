import React from "react";
import { useNavigate } from "react-router-dom";

import { Icon, NavBar, BackToTop, Typography, AnimatedText } from "app-design";

import { Box, SvgIcon, useTheme } from "@mui/material";

import { Logo } from "../../../assets";
import { FooterStyles } from "./styles";

export function Footer() {
    const navigate = useNavigate();
    const {
        palette: { secondary },
    } = useTheme();

    return (
        <FooterStyles>
            <NavBar />
            <Box className="footer-left-section">
                <SvgIcon
                    className="footer-logo"
                    component={Logo}
                    inheritViewBox
                    onClick={() => {
                        navigate("/");
                    }}
                />
                <Typography variant="p3" color={secondary.main}>
                    &copy; {new Date().getFullYear()} all rights reserved.
                </Typography>
            </Box>
            <Box className="footer-right-section">
                <div className="footer-social-info">
                    <div className="social-icons">
                        <a href="https://www.facebook.com/Inomma" target="_blank" className="social-icon">
                            <Icon icon="Facebook" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/inomma/mycompany/"
                            target="_blank"
                            className="social-icon"
                        >
                            <Icon icon="Linkedin" />
                        </a>
                    </div>
                    <a href="mailto:hello@inomma.com" className="footer-email">
                        <AnimatedText variant="p1">hello@inomma.com</AnimatedText>
                    </a>
                </div>
                <BackToTop />
            </Box>
        </FooterStyles>
    );
}
