import React from "react";

import { TVariants, Typography } from "app-design";
import { useElementOnScreen } from "common";
import { AnimatePresence, motion, Variants } from "framer-motion";

import { useTheme } from "@mui/material";

interface SplitTextProps {
    children: string;
    typoVariant: TVariants;
    spaceTypoVariant: TVariants;
    initial?: { [key: string]: string };
    variants?: Variants;
    className?: string;
    color?: string;
}

export function SplitText({ children, typoVariant, spaceTypoVariant, className, color }: SplitTextProps) {
    const theme = useTheme();
    const words = children.split(" ");
    const wordsByLetters = words.map((word, index) => {
        if (index + 1 !== words.length) {
            word = word + " ";
        }
        return word.split("");
    });

    const [isVisible, containerRef] = useElementOnScreen();
    const [animationHidden, setAnimationHidden] = React.useState(false);

    React.useEffect(() => {
        if (isVisible && !animationHidden) {
            setAnimationHidden(true);
        }
    }, [animationHidden, isVisible]);

    return (
        <AnimatePresence>
            {wordsByLetters.map((letters, index) => {
                return (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        ref={containerRef ? containerRef : null}
                        className={className ? className : "inm-split-text"}
                    >
                        <div key={children + index} style={{ display: "inline-block", overflow: "hidden" }}>
                            {letters.map((letter, index) => {
                                return (
                                    <motion.div
                                        initial={{ y: "100%" }}
                                        key={index}
                                        variants={{
                                            visible: (j: number) => ({
                                                y: 0,
                                                transition: {
                                                    type: "tween",
                                                    duration: 1,
                                                    delay: j * 0.04,
                                                },
                                            }),
                                        }}
                                        animate={isVisible || animationHidden ? "visible" : ""}
                                        style={{ display: "inline-block", willChange: "transform" }}
                                        custom={index}
                                    >
                                        {letter === " " ? (
                                            <Typography variant={spaceTypoVariant}>&nbsp;</Typography>
                                        ) : (
                                            <Typography
                                                color={color ? color : theme.palette.primary.main}
                                                variant={typoVariant}
                                            >
                                                {letter}
                                            </Typography>
                                        )}
                                    </motion.div>
                                );
                            })}
                        </div>
                    </motion.div>
                );
            })}
        </AnimatePresence>
    );
}
