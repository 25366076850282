import React from "react";

import { Typography, MainButton } from "app-design";
import { ICareerItem } from "common";

import { Box, useTheme } from "@mui/material";

import { CareersListWrapper } from "./styles";

type CareerItem = {
    title: string;
    list: string[];
};

const ListItems = ({
    list,
    descriptionColor,
    titleColor,
}: {
    list: CareerItem;
    descriptionColor?: string;
    titleColor?: string;
}) => {
    return (
        <Box key={list.title} className="career-item-section">
            <Box className="career-item-title">
                <Typography variant="p1" fontWeight="medium" color={titleColor}>
                    {list.title}
                </Typography>
            </Box>
            <Box>
                {list.list.map((item, index) => {
                    return (
                        <Typography
                            color={descriptionColor}
                            className="list"
                            variant="p2"
                            key={`${new Date().getMilliseconds()}--${index}`}
                        >
                            &#183; {item}
                        </Typography>
                    );
                })}
            </Box>
        </Box>
    );
};
interface CareerItemProps {
    item: ICareerItem;
}
export function CareerItem(props: CareerItemProps) {
    const { item } = props;
    const theme = useTheme();

    return (
        <CareersListWrapper>
            <Box className="career-item-type">
                <Typography variant="p4">{item.type} </Typography>
            </Box>

            <Box>
                <Box className="career-item-title">
                    <Typography variant="p1" fontWeight="medium">
                        {item.title}
                    </Typography>
                </Box>
                <Box className="career-item-description">
                    <Typography variant="p2" color={theme.palette?.other?.footerLogo}>
                        {item.description}
                    </Typography>
                </Box>
            </Box>
            <Box>
                {item.requirements.map((job: CareerItem) => {
                    return ListItems({
                        list: job,
                        descriptionColor: theme.palette?.other?.footerLogo,
                        titleColor: theme.palette?.secondary.oldLace,
                    });
                })}
            </Box>
            <Box>
                <MainButton variant={"link"} text="Apply" />
            </Box>
        </CareersListWrapper>
    );
}
