import styled from "styled-components";

export const TopSectionWrapper = styled.section`
    position: relative;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.down("sm")} {
        height: unset;
    }
`;
