import React from "react";

import { ImageAnimation, Typography } from "app-design";

import { useTheme } from "@mui/material";

import { ProjectWrapper } from "./styled";

interface IProjectProps {
    project: { id: number; title: string; date: number; image: string; images: string[] };
    isAnimated?: boolean;
}

export function Project({ project, isAnimated = false }: IProjectProps) {
    const theme = useTheme();

    return (
        <ProjectWrapper>
            {isAnimated ? (
                <ImageAnimation className={`project project-${project.id}`}>
                    <img src={project.image} alt={`project-${project.id}`} />
                    <Typography variant="p2" color={theme.palette.secondary.main}>
                        {project.title}
                    </Typography>
                </ImageAnimation>
            ) : (
                <div className={`project project-${project.id}`}>
                    <img src={project.image} alt={`project-${project.id}`} />
                    <Typography variant="p2" color={theme.palette.secondary.main}>
                        {project.title}
                    </Typography>
                </div>
            )}
        </ProjectWrapper>
    );
}
