import React, { ReactElement } from "react";
import { isTablet, isMobile } from "react-device-detect";

import gsap from "gsap";

import { useMediaQuery, Theme } from "@mui/material";

import { PointerStyles } from "./styles";

export interface PointerDecoratorProps {
    children: ReactElement;
}

export function PointerDecorator({ children }: PointerDecoratorProps) {
    const isMobileQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const moveCursor = (evt: MouseEvent) => {
        const mouseX = evt.clientX;
        const mouseY = evt.clientY;

        gsap.set(".inm-pointer", {
            x: mouseX,
            y: mouseY,
        });
        gsap.to(".inm-pointer", {
            duration: 0.8,
            x: mouseX,
            y: mouseY,
        });
    };

    React.useEffect(() => {
        if (!isMobile || !isTablet) {
            window.addEventListener("mousemove", moveCursor);
        }

        return () => window.removeEventListener("mousemove", moveCursor);
    }, [isMobileQuery]);

    if (isMobile || isTablet) {
        return children;
    }

    return (
        <PointerStyles>
            <div className="inm-pointer" data-testid="pointer-decorator" />
            {children}
        </PointerStyles>
    );
}
