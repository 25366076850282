import React, { RefObject, useEffect } from "react";

interface IIntersectionOptions {
    root: Element | null;
    rootMargin: string;
    threshold: number;
}

const defaultOptions: IIntersectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
};

export const useElementOnScreen = (options?: IIntersectionOptions): [boolean, RefObject<HTMLDivElement>] => {
    options = defaultOptions;
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = React.useState(false);

    const callBackFunction = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry) {
            setIsVisible(entry.isIntersecting);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(callBackFunction, options);
        const containerRefCurrent = containerRef.current;

        if (containerRefCurrent) observer.observe(containerRefCurrent);

        return () => {
            if (containerRefCurrent) observer.unobserve(containerRefCurrent);
        };
    }, [options, containerRef]);

    return [isVisible, containerRef];
};
