import React, { useEffect, useRef, useState } from "react";

import { Typography } from "app-design";
import classNames from "classnames";

import { useTheme } from "@mui/material";

import { NoiseAnimation } from "../Noise";
import { LoaderWrapper } from "./styled";

const LOADING_TIME = 15;

export function Loader() {
    const theme = useTheme();
    const [loadingNumber, setLoadingNumber] = useState(0);
    const counterRef = useRef<NodeJS.Timeout | null>(null);

    const drawPercent = (percent: number) => {
        if (percent >= 100) {
            return "100%";
        }
        if (percent < 10) {
            return `00${percent}%`;
        }
        return `0${percent}%`;
    };

    useEffect(() => {
        counterRef.current = setInterval(() => {
            if (loadingNumber === 100) {
                if (counterRef.current) {
                    clearInterval(counterRef.current);
                }
            } else {
                setLoadingNumber(loadingNumber + 1);
            }
        }, LOADING_TIME);
        return () => {
            if (counterRef.current) {
                clearInterval(counterRef.current);
            }
        };
    }, [counterRef, loadingNumber]);

    return (
        <LoaderWrapper
            className={classNames({
                animation: loadingNumber >= 100,
            })}
        >
            <div
                className={classNames({
                    "black-animation": loadingNumber >= 100,
                })}
            />
            <div className="loader">
                {drawPercent(loadingNumber)
                    .split("")
                    .map((digit, index) => {
                        return (
                            <Typography
                                key={index}
                                className="loader-digit"
                                variant="h1"
                                color={theme.palette?.other?.footerLogo}
                                align="right"
                            >
                                {digit}
                            </Typography>
                        );
                    })}
            </div>

            <NoiseAnimation />
        </LoaderWrapper>
    );
}
