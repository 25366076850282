import React from "react";

import { NoiseWrapper } from "./styled";

export function NoiseAnimation() {
    return (
        <NoiseWrapper>
            <div className="noise" />
        </NoiseWrapper>
    );
}
