import { useEffect, useRef } from "react";

export const useScrollToTop = (): [() => void] => {
    const appLayout = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        appLayout.current = document.querySelector("#app-layout") as HTMLDivElement;

        if (appLayout.current) {
            appLayout.current.scrollTop = 0;
        }
    }, []);

    const scrollToTop = () => {
        if (appLayout.current) {
            appLayout.current.scrollTop = 0;
        }
    };

    return [scrollToTop];
};
