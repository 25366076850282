import styled from "styled-components";

const CURSOR_SIZE = 40;

export const SHAPE_SIZES = {
    small: 270,
    medium: 440,
    big: 625,
    large: 810,
};

export const CursorStyles = styled.div`
    position: relative;
    &.inm-cursor {
        & .inm-cursor {
            &__pointer {
                position: fixed;
                width: ${CURSOR_SIZE}px;
                height: ${CURSOR_SIZE}px;
                margin: -10px 0 0 -10px;
                background-color: ${({ theme }) => theme.palette.secondary.blue};
                border-radius: 50%;
                will-change: transform;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                pointer-events: none;
                z-index: 1000000000;
            }
            &__shapes {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: ${({ theme }) => theme.palette.primary.main};
                overflow: hidden;
            }
            &__shape {
                position: absolute;
                border-radius: 50%;
                will-change: transform;
                &.shape-1 {
                    width: ${SHAPE_SIZES.large}px;
                    height: ${SHAPE_SIZES.large}px;
                    margin: -412px 0 0 -412px;
                    background-color: ${({ theme }) => theme.palette.secondary.blue};
                }
                &.shape-2 {
                    width: ${SHAPE_SIZES.big}px;
                    height: ${SHAPE_SIZES.big}px;
                    margin: -325px 0 0 -325px;
                    background-color: ${({ theme }) => theme.palette.other.yellow};
                }
                &.shape-3 {
                    width: ${SHAPE_SIZES.medium}px;
                    height: ${SHAPE_SIZES.medium}px;
                    margin: -220px 0 0 -220px;
                    background-color: ${({ theme }) => theme.palette.primary.orange};
                }
                &.shape-4 {
                    width: ${SHAPE_SIZES.small}px;
                    height: ${SHAPE_SIZES.small}px;
                    margin: -135px 0 0 -135px;
                    background-color: ${({ theme }) => theme.palette.primary.main};
                }
            }
            &__content {
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
                align-items: center;
                mix-blend-mode: darken;
                background-color: ${({ theme }) => theme.palette.background.black};
            }
        }
    }
`;
