import styled from "styled-components";

export const NavBarWrapper = styled.nav`
    display: flex;
    margin-left: auto;
    .navbar-item {
        &.active {
            color: ${({ theme }) => theme.palette.primary.main};
        }
        margin-left: ${({ theme }) => theme.spacing(26)};
        ${({ theme }) => theme.breakpoints.down("xl")} {
            margin-left: ${({ theme }) => theme.spacing(22)};
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            margin-left: ${({ theme }) => theme.spacing(17)};
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            margin-left: ${({ theme }) => theme.spacing(14)};
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            margin-left: ${({ theme }) => theme.spacing(10)};
        }
    }
`;
