export default {
    h0: { letterSpacing: 0 },
    h1: { letterSpacing: 0 },
    h2: { letterSpacing: 0 },
    h3: { letterSpacing: 0 },
    h4: { letterSpacing: 0 },
    p0: { letterSpacing: 0 },
    p1: { letterSpacing: 0 },
    p2: { letterSpacing: 0 },
    p3: { letterSpacing: 0 },
    p4: { letterSpacing: 0 },
} as const;
