import React, { useRef } from "react";

import { motion } from "framer-motion";

import { Header, Footer } from "../../containers";
import { AppLayoutWrapper } from "./styled";

interface IAppDecoratorProps {
    children: React.ReactNode;
    isFooterEnabled: boolean;
}

export function AppDecorator(props: IAppDecoratorProps) {
    const { children, isFooterEnabled } = props;

    const header = useRef<HTMLElement | null>(null);
    const appLayout = useRef<HTMLElement | null>(null);

    const handelScroll = () => {
        appLayout.current = document.getElementById("app-layout");
        header.current = document.getElementById("header");
        let prevScroll = appLayout.current?.scrollTop;

        if (appLayout.current !== null) {
            appLayout.current.addEventListener("scroll", () => {
                if (appLayout.current !== null) {
                    const currentScrollPos = appLayout.current?.scrollTop;
                    if (prevScroll && prevScroll > currentScrollPos && header.current !== null) {
                        header.current.style.top = "0";
                    } else {
                        if (header.current !== null) {
                            header.current.style.top = "-110px";
                        }
                    }
                    prevScroll = currentScrollPos;
                }
            });
        }
    };

    return (
        <AppLayoutWrapper onScroll={handelScroll} data-test="app-layout-test-id" id="app-layout">
            <motion.div
                className="black-animation"
                initial={{ x: "100%" }}
                exit={{ x: 0 }}
                transition={{ duration: 0.3, delay: 0.15 }}
            />
            <motion.div
                className="black-animation"
                initial={{ x: 0 }}
                animate={{ x: "-100%" }}
                transition={{ duration: 0.3, delay: 0.15 }}
            />
            <Header />
            {children}
            {isFooterEnabled ? <Footer /> : null}
        </AppLayoutWrapper>
    );
}
