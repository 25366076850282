import React, { ReactNode } from "react";

import { motion, Variants } from "framer-motion";

interface ImageAnimationProps {
    children: ReactNode;
    variants?: Variants;
    className?: string;
}

const cardVariants: Variants = {
    offscreen: {
        y: 100,
        opacity: 0,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            y: {
                duration: 1,
            },
        },
    },
};

export function ImageAnimation({ children, className }: ImageAnimationProps) {
    return (
        <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
            <motion.div variants={cardVariants} className={className}>
                {children}
            </motion.div>
        </motion.div>
    );
}
