import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import { PointerDecorator } from "../decorators";
import AnimatedRoutes from "./AnimatedRoutes";

export function MainRouter() {
    return (
        <BrowserRouter>
            <PointerDecorator>
                <Suspense fallback={<div>Loading ....</div>}>
                    <AnimatedRoutes />
                </Suspense>
            </PointerDecorator>
        </BrowserRouter>
    );
}
