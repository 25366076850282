import React from "react";
import { ErrorBoundary } from "react-error-boundary";

export interface ErrorBoundaryWrapperProps {
    children: React.ReactNode;
}

export const ErrorBoundaryWrapper = (props: ErrorBoundaryWrapperProps) => {
    const { children } = props;
    return (
        <ErrorBoundary
            FallbackComponent={({ error, resetErrorBoundary }) => {
                return (
                    <div role="alert">
                        <p>Something went wrong:</p>
                        <pre>{error.message}</pre>
                        <button onClick={resetErrorBoundary}>Try again</button>
                    </div>
                );
            }}
        >
            {children}
        </ErrorBoundary>
    );
};
