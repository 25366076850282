import React from "react";

import CircleL from "../../../../public/img/circle/circleL.png";
import CircleM from "../../../../public/img/circle/circleM.png";
import CircleS from "../../../../public/img/circle/circleS.png";
import { CirclesWrapper } from "./styled";

export function Circles() {
    const circles = [CircleS, CircleM, CircleL];

    return (
        <CirclesWrapper>
            {circles.map((item, i) => {
                return (
                    <div key={`circle-${i}`} className={`circle circle-${i}`}>
                        <img src={item} alt="circle" draggable={false} />
                    </div>
                );
            })}
        </CirclesWrapper>
    );
}
