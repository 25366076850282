import React from "react";

import { useElementOnScreen } from "common";
import { motion, Variants } from "framer-motion";

import { useTheme } from "@mui/material";

import { Typography, TVariants } from "../Typography";

export interface ParagraphProps {
    children: string;
    typoVariant: TVariants;
    initial?: { [key: string]: string };
    variants?: Variants;
    className?: string;
}

const container = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.01,
        },
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 1,
            duration: 0.1,
        },
    },
};

export function ParagraphAnimation({ children, typoVariant, className }: ParagraphProps) {
    const theme = useTheme();
    const words = children.split(" ");
    const [isVisible, containerRef] = useElementOnScreen();
    const [animationHidden, setAnimationHidden] = React.useState(false);

    React.useEffect(() => {
        if (isVisible && !animationHidden) {
            setAnimationHidden(true);
        }
    }, [animationHidden, isVisible]);

    return (
        <motion.div
            ref={containerRef}
            style={{
                overflow: "hidden",
                display: "block",
            }}
            className={className}
            variants={container}
            initial="hidden"
            animate="visible"
        >
            {words.map((word, index) => {
                return (
                    <div key={index} style={{ display: "inline-block", overflow: "hidden" }}>
                        <motion.div
                            initial={{ y: "100%" }}
                            custom={index}
                            style={{ display: "inline-block", willChange: "transform" }}
                            animate={isVisible || animationHidden ? "visible" : ""}
                            variants={{
                                visible: () => ({
                                    y: 0,
                                    transition: {
                                        delay: 0.2,
                                        type: "tween",
                                        duration: 0.8,
                                    },
                                }),
                            }}
                        >
                            <Typography variant={typoVariant} color={theme.palette.secondary.main}>
                                {word + (index !== words.length - 1 ? "\u00A0" : "")}
                            </Typography>
                        </motion.div>
                    </div>
                );
            })}
        </motion.div>
    );
}
