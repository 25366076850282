import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Typography, ArrowDown } from "app-design";
import { Slides } from "common";

import { SvgIcon } from "@mui/material";

import { SliderWrapper, CustomDotWrapper, RightButtonStyle } from "./styled";

interface ButtonGroupProps {
    next?: () => void;
}
interface CustomDotProps {
    index?: number;
    active?: boolean;
}

export function MainSlider() {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 3000, min: 1921 },
            items: Slides.length,
            slidesToSlide: 1,
            partialVisibilityGutter: 100,
        },
        largeDesktop: {
            breakpoint: { max: 1920, min: 1441 },
            items: Slides.length,
            slidesToSlide: 1,
            partialVisibilityGutter: 10,
        },
        desktop: {
            breakpoint: { max: 1440, min: 769 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 100,
        },
        tablet: {
            breakpoint: { max: 768, min: 465 },
            items: Slides.length,
            slidesToSlide: 1,
            partialVisibilityGutter: 100,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: Slides.length,
            slidesToSlide: 1,
            partialVisibilityGutter: 100,
        },
    };

    const RightButton = ({ next }: ButtonGroupProps) => {
        return (
            <RightButtonStyle>
                <SvgIcon onClick={() => next?.()} component={ArrowDown} inheritViewBox className="arrow-right" />
            </RightButtonStyle>
        );
    };

    const CustomDot = ({ index = 0, active }: CustomDotProps) => {
        return (
            <CustomDotWrapper active={active}>
                <Typography variant="p1" color="inherit">
                    {index + 1}/{Slides.length}
                </Typography>
            </CustomDotWrapper>
        );
    };

    return (
        <SliderWrapper>
            <Carousel
                renderButtonGroupOutside={true}
                customButtonGroup={<RightButton />}
                customDot={<CustomDot />}
                itemClass="inm-carousel-item"
                containerClass="inm-carousel-container"
                responsive={responsive}
                infinite
                autoPlay
                autoPlaySpeed={8000}
                transitionDuration={2000}
                customTransition="all ease 2000ms"
                arrows={false}
                showDots={true}
                partialVisible={true}
                draggable={true}
                swipeable={true}
            >
                {Slides.map((slide, i) => (
                    <div key={`slide-${i}`} className="slider-wrapper-item">
                        <div className="number-bg">
                            <Typography className="number-bg-text" variant="h0">
                                {i + 1}
                            </Typography>
                        </div>
                        <div className="slider-item-title">
                            <Typography variant="h4">{slide.firstTitle}</Typography>
                            <Typography className="slider-item-title-second" variant="h4">
                                {slide.secondTitle}
                            </Typography>
                        </div>
                        {slide.boldTexts ? (
                            <div className="slider-item-description">
                                {slide.description.map((description, index) => {
                                    return (
                                        <Typography key={index} variant="p1" color="inherit">
                                            <strong>{slide.boldTexts[index]}</strong>
                                            {description}
                                        </Typography>
                                    );
                                })}
                            </div>
                        ) : (
                            <Typography variant="p1" className="slider-item-description">
                                {slide.description}
                            </Typography>
                        )}
                    </div>
                ))}
            </Carousel>
        </SliderWrapper>
    );
}
