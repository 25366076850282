import styled from "styled-components";

export const HeaderLogoWrapper = styled.div`
    width: 100%;
    z-index: 1400;
    display: flex;
    align-items: center;
    cursor: pointer;
    .header-logo {
        color: ${({ theme }) => theme.palette.secondary.blue};
        width: 218px;
        height: 40px;
        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 175px;
            height: 32px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 153px;
            height: 28px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 120px;
            height: 22px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            width: 98px;
            height: 18px;
        }
    }
`;
