import React, { useMemo } from "react";

import classNames from "classnames";

import { SvgIcon } from "@mui/material";

import ButtonOrangeBackground from "../../../assets/button-orange-background.png";
import ButtonYellowBackground from "../../../assets/button-yellow-background.png";
import {
    ButtonBlueIcon,
    ButtonLeftBottom,
    ButtonLeftTop,
    ButtonOnlyIcon,
    ButtonSecondRightTop,
    ButtonOnlyIconLine,
    ButtonLeftBorder,
    ButtonRightBorder,
} from "../../icons/general";
import { ButtonWrapper } from "./styles";

export interface ButtonProps {
    className?: string;
    variant?: "primary" | "secondary" | "link" | "text" | "onlyIcon";
    text?: string;
    isOrangeCircle?: boolean;
    linkTo?: string;
    onClick?: () => void;
    isOpen?: boolean;
}

export function MainButton(props: ButtonProps) {
    const { className, text, variant, isOrangeCircle, linkTo, onClick, isOpen } = props;
    const buttonText: string[] = useMemo(() => (text ? [...text] : []), [text]);

    switch (variant) {
        case "primary":
            return (
                <ButtonWrapper
                    variant={variant}
                    data-test="inm-primary-test-button"
                    className={classNames("buttonHover ", className)}
                    onClick={onClick}
                >
                    <SvgIcon component={ButtonLeftBorder} inheritViewBox className="button-left-border" />
                    <div className="button-top-icons">
                        <SvgIcon component={ButtonLeftTop} className="button-left-top-icon" inheritViewBox />
                        <SvgIcon component={ButtonSecondRightTop} className="button-right-top-icon" inheritViewBox />
                    </div>
                    <div className="inm-text-animation">
                        <div className="default-item">
                            {buttonText.map((char, index) => {
                                return (
                                    <span
                                        key={`${char}__${index}`}
                                        className={char === " " ? "empty-space" : ""}
                                        style={{ "--index": index } as React.CSSProperties}
                                    >
                                        {`${char}`}
                                    </span>
                                );
                            })}
                        </div>
                        <div className="clone-item">
                            {buttonText.map((char, index) => (
                                <span
                                    key={`${index}__${char}`}
                                    style={{ "--index": index } as React.CSSProperties}
                                    className={char === " " ? "empty-space" : ""}
                                >
                                    {char}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="button-bottom-icons">
                        <SvgIcon component={ButtonLeftBottom} className="button-left-bottom-icon" inheritViewBox />
                        <SvgIcon component={ButtonBlueIcon} className="button-blue-icon" inheritViewBox />
                    </div>
                    <SvgIcon component={ButtonRightBorder} inheritViewBox className="button-right-border" />
                </ButtonWrapper>
            );
        case "link":
            return (
                <ButtonWrapper
                    variant="link"
                    data-test="inm-link-test-button"
                    className={classNames("buttonHover", className)}
                    onClick={onClick}
                >
                    <span className="link link--iocaste">
                        <label>{text}</label>
                        <svg
                            className="link__graphic link__graphic--slide"
                            width="300%"
                            height="100%"
                            viewBox="0 0 1200 60"
                            preserveAspectRatio="none"
                        >
                            <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
                        </svg>
                    </span>
                </ButtonWrapper>
            );
        case "text":
            return (
                <ButtonWrapper
                    variant="text"
                    data-test="inm-text-test-button"
                    className={classNames("buttonHover", className)}
                >
                    <a href={`${linkTo}`} className="link link--herse">
                        <span>{text}</span>
                        <svg
                            className="link__graphic link__graphic--stroke link__graphic--arc"
                            width="100%"
                            height="18"
                            viewBox="0 0 59 18"
                        >
                            <path d="M.945.149C12.3 16.142 43.573 22.572 58.785 10.842" pathLength="1" />
                        </svg>
                    </a>
                </ButtonWrapper>
            );
        case "secondary":
            return (
                <ButtonWrapper
                    variant="secondary"
                    data-test="inm-secondary-test-button"
                    className={classNames("buttonHover", className)}
                >
                    <img
                        alt="secondary-button"
                        src={isOrangeCircle ? ButtonOrangeBackground : ButtonYellowBackground}
                    />
                    <span>{text}</span>
                </ButtonWrapper>
            );
        default:
            return (
                <ButtonWrapper
                    variant="onlyIcon"
                    data-test="inm-secondary-default-button"
                    className={classNames(isOpen ? "buttonHover" : "", className)}
                    onClick={onClick}
                >
                    <SvgIcon component={ButtonOnlyIconLine} className="button-only-icon-horizontal" inheritViewBox />
                    <SvgIcon component={ButtonOnlyIcon} className="button-only-icon-vertical" inheritViewBox />
                </ButtonWrapper>
            );
    }
}
