import { createTheme } from "@mui/material/styles";

import palette from "./palette";
import typography from "./typography";

export * from "./mixins";
export * from "./GlobalStyles";

export const MuiTheme = createTheme({
    breakpoints: {
        values: {
            xs: 641,
            sm: 769,
            s: 1025,
            md: 1441,
            lg: 1793,
            xl: 1921,
            xxl: 2561,
        },
    },
    typography: {
        fontSize: 14,
        htmlFontSize: 16,
        ...typography,
    },
    palette,
    spacing: 4,
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h0: "h2",
                    h1: "h3",
                    h2: "h4",
                    h3: "h5",
                    h4: "h6",
                    p0: "p",
                    p1: "p",
                    p2: "p",
                    p3: "p",
                    p4: "p",
                },
            },
        },
    },
});
