import styled from "styled-components";

export const ProjectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .project {
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing(6)};
        ${({ theme }) => theme.breakpoints.down("xl")} {
            gap: ${({ theme }) => theme.spacing(4)};
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            gap: ${({ theme }) => theme.spacing(3)};
        }
    }
    img {
        border-radius: 16px;
        height: 666px;
        ${({ theme }) => theme.breakpoints.down("xl")} {
            height: 500px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            height: 376px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            height: 280px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            height: 376px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            height: unset;
            width: 100%;
        }
    }
    :hover {
        p {
            transition: all ease 0.4s;
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }
`;
