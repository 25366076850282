import React, { ReactElement } from "react";
import { isTablet, isMobile } from "react-device-detect";

import gsap from "gsap";

import { useMediaQuery, Theme } from "@mui/material";

import { CursorStyles, SHAPE_SIZES } from "./styles";

export interface CursorDecoratorProps {
    children: ReactElement;
}

export function CursorDecorator(props: CursorDecoratorProps) {
    const { children } = props;
    const isMobileQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const wrapper = React.useRef<HTMLDivElement | null>(null);

    const moveCursor = (evt: MouseEvent) => {
        const wrapperBounds = wrapper.current?.getBoundingClientRect();

        if (!wrapperBounds) return;

        const mouseX = evt.clientX - wrapperBounds.x;
        const mouseY = evt.clientY - wrapperBounds.y;

        const q = gsap.utils.selector(wrapper);

        if (
            mouseY < -SHAPE_SIZES.big / 2 ||
            mouseY > wrapperBounds.height + SHAPE_SIZES.big / 2 ||
            mouseX < -SHAPE_SIZES.big / 2 ||
            mouseX > wrapperBounds.width + SHAPE_SIZES.big / 2
        ) {
            return;
        }

        gsap.to(q(".inm-cursor__shape"), {
            duration: 0.8,
            x: mouseX,
            y: mouseY,
            stagger: -0.1,
        });
    };

    React.useEffect(() => {
        if (!isMobile || !isTablet) {
            window.addEventListener("mousemove", moveCursor);
        }

        return () => window.removeEventListener("mousemove", moveCursor);
    }, [isMobileQuery]);

    if (isMobile || isTablet) {
        return children;
    }

    return (
        <CursorStyles className="inm-cursor" ref={wrapper} data-testid="cursor-wrapper">
            <div className="inm-cursor__shapes">
                <div className="inm-cursor__shape shape-1 inm-shape" />
                <div className="inm-cursor__shape shape-2 inm-shape" />
                <div className="inm-cursor__shape shape-3 inm-shape" />
                <div className="inm-cursor__shape shape-4 inm-shape" />
            </div>
            <div className="inm-cursor__content">{children}</div>
        </CursorStyles>
    );
}
