import { TextWidth, LeftMargin } from "common";
import styled from "styled-components";

interface ICustomDotWrapper {
    active?: boolean;
}

export const SliderWrapper = styled.div`
    position: relative;
    margin-top: ${({ theme }) => theme.spacing(25)};
    ${({ theme }) => theme.breakpoints.down("sm")} {
        margin-top: ${({ theme }) => theme.spacing(8)};
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -20%;
        right: -56px;
        width: 160px;
        height: 120%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 1) 80%);
        z-index: 1;
        ${({ theme }) => theme.breakpoints.down("xl")} {
            right: -42px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            right: -24px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            right: -20px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            display: none;
        }
    }
    .react-multi-carousel-dot-list {
        position: absolute;
        left: 1600px;
        ${({ theme }) => theme.breakpoints.down("xl")} {
            left: 1260px;
        }
        ${({ theme }) => theme.breakpoints.down("lg")} {
            left: 1060px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            left: 900px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            left: 645px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            left: 560px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            top: 360px;
            left: calc(100vw - 55px);
        }
    }
    .inm-carousel-container {
        width: 5500px;
        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 4000px;
        }
        ${({ theme }) => theme.breakpoints.down("lg")} {
            width: 3500px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 1200px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 800px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            width: 2200px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            height: 305px;
        }
    }
    .react-multi-carousel-list {
        overflow: inherit;
    }
    .slider-wrapper-item {
        display: flex;
        flex-direction: column;
        width: 1200px;
        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 900px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 680px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            width: 480px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 416px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            width: 100%;
        }
        .number-bg {
            position: absolute;
            top: -180px;
            left: 232px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                top: -160px;
                left: 140px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                top: -140px;
                left: 84px;
            }
            ${({ theme }) => theme.breakpoints.down("sm")} {
                top: -120px;
                left: 40px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                top: -110px;
                left: 36px;
            }
            h2 {
                font-weight: 100;
            }
            .number-bg-text {
                color: ${({ theme }) => theme.palette.secondary.blue};
                opacity: 8%;
                user-select: none;
            }
        }
        .slider-item-title {
            display: flex;
            z-index: 1;
            user-select: none;
            .slider-item-title-second {
                margin-left: ${({ theme }) => theme.spacing(3)};
                color: ${({ theme }) => theme.palette.primary.orange};
            }
        }
        .slider-item-description {
            display: flex;
            flex-direction: column;
            z-index: 1;
            user-select: none;
            color: ${({ theme }) => theme.palette.secondary.main};
            margin-top: ${({ theme }) => theme.spacing(15)};
            margin-left: ${({ theme }) => theme.spacing(28)};
            width: 890px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                margin-top: ${({ theme }) => theme.spacing(14)};
                margin-left: ${({ theme }) => theme.spacing(25)};
                width: 750px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                margin-top: ${({ theme }) => theme.spacing(11)};
                width: 600px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                margin-top: ${({ theme }) => theme.spacing(8)};
                margin-left: ${({ theme }) => theme.spacing(18)};
                width: 405px;
            }
            ${({ theme }) => theme.breakpoints.down("sm")} {
                margin-left: ${({ theme }) => theme.spacing(10)};
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                margin-top: ${({ theme }) => theme.spacing(5)};
                margin-left: 0;
                max-width: calc(100vw - 32px);
                width: unset;
            }
        }
    }
    .arrow-right {
        cursor: pointer;
        position: absolute;
        color: ${({ theme }) => theme.palette.primary.main};
        z-index: 3;
        width: 200px;
        height: 200px;
        left: 55%;
        top: calc(50% - 100px);
        transform: rotate(-90deg);
        ${({ theme }) => theme.breakpoints.down("xl")} {
            width: 180px;
            height: 180px;
            top: calc(50% - 90px);
            left: ${LeftMargin.xl + TextWidth.xl + 25}px;
        }
        ${({ theme }) => theme.breakpoints.down("lg")} {
            left: ${LeftMargin.lg + TextWidth.lg}px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            width: 120px;
            height: 120px;
            top: calc(50% - 60px);
            left: ${LeftMargin.md + TextWidth.md + 50}px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            width: 80px;
            height: 80px;
            top: calc(50% - 40px);
            left: ${LeftMargin.s + TextWidth.s + 50}px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            left: ${LeftMargin.sm + TextWidth.sm + 50}px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            width: 64px;
            height: 64px;
            top: 100%;
            left: 200px;
        }
    }
`;

export const CustomDotWrapper = styled.li<ICustomDotWrapper>`
    color: ${({ theme }) => theme.palette.secondary.main};
    display: ${({ active }) => (active ? "block" : "none")};
    position: absolute;
    left: 0;
`;

export const RightButtonStyle = styled.div`
    color: ${({ theme }) => theme.palette.primary.main};
    :active {
        svg {
            color: ${({ theme }) => theme.palette.secondary.main};
        }
    }
    :hover {
        ${({ theme }) => theme.breakpoints.up("sm")} {
            svg {
                transition: all ease 0.4s;
                color: ${({ theme }) => theme.palette.secondary.main};
            }
        }
    }
`;
