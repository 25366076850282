import styled from "styled-components";

export const AppLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    ${({ theme }) => theme.breakpoints.down("sm")} {
        height: 100%;
        height: -webkit-fill-available;
    }
    .black-animation {
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 9999;
        background-color: ${({ theme }) => theme.palette.background.black};
    }
`;
