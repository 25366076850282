import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AnimatedText } from "app-design";
import { MenuList, useScrollToTop } from "common";

import { useTheme } from "@mui/material";

import { NavBarWrapper } from "./styled";

export function NavBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const activePage = useMemo(() => location.pathname, [location]);
    const theme = useTheme();
    const [scrollToTop] = useScrollToTop();

    return (
        <NavBarWrapper>
            {MenuList.map((page: string, index: number) => {
                return (
                    <div
                        className="navbar-item"
                        key={`${index}-navbar-item`}
                        onClick={() => {
                            navigate(`/${page}`);
                            if (location.pathname === `/${page}`) {
                                scrollToTop();
                            }
                        }}
                    >
                        <AnimatedText
                            variant="p2"
                            isColoredHover
                            color={
                                activePage === `/${page}` || activePage.includes(page)
                                    ? theme.palette.primary.main
                                    : theme.palette.secondary.main
                            }
                        >
                            {page}
                        </AnimatedText>
                    </div>
                );
            })}
        </NavBarWrapper>
    );
}
