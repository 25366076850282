import React from "react";

import { MyThemeProvider, Loader } from "app-design";

import { ErrorBoundaryWrapper } from "./decorators";
import { MainRouter } from "./routes";

export function App() {
    return (
        <ErrorBoundaryWrapper>
            <MyThemeProvider>
                <Loader />
                <MainRouter />
            </MyThemeProvider>
        </ErrorBoundaryWrapper>
    );
}
