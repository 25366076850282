import React from "react";

import { Sun, ArrowDown } from "app-design";

import { SvgIcon } from "@mui/material";

import { ArrowSunWrapper } from "./styled";

export function ArrowSun() {
    return (
        <ArrowSunWrapper data-testid="c-about-us-container">
            <div className="sun-arrow">
                <SvgIcon className="sun" component={Sun} inheritViewBox />
                <SvgIcon className="arrow" component={ArrowDown} inheritViewBox />
            </div>
        </ArrowSunWrapper>
    );
}
