import React from "react";

import { HeaderLogo, NavBar, MobileMenu } from "app-design";

import { Theme, useMediaQuery } from "@mui/material";

import { HeaderWrapper } from "./styles";

export function Header() {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    return (
        <HeaderWrapper data-testid="c-header" id="header">
            <HeaderLogo />
            {isMobile ? <MobileMenu /> : <NavBar />}
        </HeaderWrapper>
    );
}
