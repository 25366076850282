import styled from "styled-components";

export const CareersListWrapper = styled.div`
    .careers-header {
        display: flex;
        align-items: baseline;
        margin-bottom: 117px;
        margin-top: 346px;

        .item-count {
            margin-left: 100px;
        }
    }

    .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 160px;
        border-top: 1px solid #e60;
        border-bottom: 1px solid #e60;

        .job-position {
        }

        ${({ theme }) => theme.breakpoints.down("xxl")} {
            height: 160px;
        }
        ${({ theme }) => theme.breakpoints.down("xl")} {
            height: 136px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            height: 135px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            height: 108px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            height: 88px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            height: 136px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .career-list {
        display: flex;
        justify-content: flex-end;
        .career-list-item {
            width: 100%;

            ${({ theme }) => theme.breakpoints.down("xxl")} {
                max-width: 1830px;
            }
            ${({ theme }) => theme.breakpoints.down("xl")} {
                max-width: 1374px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                max-width: 1278px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                max-width: 1228px;
            }
            ${({ theme }) => theme.breakpoints.down("sm")} {
                max-width: 728px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                width: 100%;
            }
        }
    }

    .career-item-type {
        width: fit-content;
        border: 1px solid #e60;
        background-color: #e60;
        border-radius: 20px;
        padding: 8px 14px;
        margin: 39px 0 83px 0px;
    }

    .career-item-title {
        margin-bottom: 40px;
    }

    .career-item-description {
        margin-bottom: 66px;
    }

    .career-item-section {
        margin-bottom: 66px;
    }
    .list {
        display: flex;
        align-items: flex-start;
    }
`;
