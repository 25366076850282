import React, { ReactElement } from "react";

import { TopSectionWrapper } from "./styled";

interface ITopSectionProps {
    children: ReactElement | ReactElement[];
}

export function TopSection({ children, ...rest }: ITopSectionProps) {
    return (
        <TopSectionWrapper className="top-section" {...rest}>
            {children}
        </TopSectionWrapper>
    );
}
