import styled, { keyframes, css } from "styled-components";

import { ButtonProps } from "./index";

const animationClose = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(480deg);
  }
`;

export const ButtonWrapper = styled.button<Partial<ButtonProps>>`
    cursor: pointer;
    ${({ variant }) =>
        variant === "primary" &&
        css`
            background-size: contain;
            background-repeat: no-repeat;

            .empty-space {
                margin: ${({ theme }) => theme.spacing(0, 1)};
            }

            box-sizing: border-box;
            border: none;
            background-color: transparent;
            width: 100%;
            position: relative;
            overflow: hidden;

            .button-left-border {
                color: transparent;
                background-color: transparent;
            }

            .button-right-border {
                color: transparent;
                background-color: transparent;
            }

            &:before {
                display: block;
                content: "";
                height: 1.5px;
                background-color: ${({ theme }) => theme.palette.primary.main};
                width: calc(100% - ${({ theme }) => theme.spacing(3)});
                margin-top: ${({ theme }) => theme.spacing(-1.75)};
                margin-left: ${({ theme }) => theme.spacing(0.75)};
                position: absolute;
                top: 7px;
                left: 4px;
                z-index: 9;
            }

            &:after {
                display: block;
                content: "";
                height: 2.5px;
                background-color: ${({ theme }) => theme.palette.primary.main};
                width: calc(100% - 8px);
                position: absolute;
                bottom: 0;
                left: 4px;
                z-index: 9;
            }

            .button-right-border {
                height: 100%;
                width: 30px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 9;
            }

            .button-left-border {
                position: absolute;
                height: 100%;
                width: 30px;
                left: 0;
                top: 0;
                z-index: 9;
            }

            .button-bottom-icons {
                svg {
                    position: absolute;
                    transition: all 1s;
                    width: 90%;
                }

                .button-blue-icon {
                    top: 150%;
                    right: -30%;
                }

                .button-left-bottom-icon {
                    top: 150%;
                    left: -25%;
                }
            }

            .button-top-icons {
                svg {
                    height: 100%;
                    width: 12%;
                    position: absolute;
                    transition: all 1s;
                }

                .button-left-top-icon {
                    left: 0;
                    top: -91%;
                }

                .button-right-top-icon {
                    right: 0;
                    top: -110%;
                }
            }

            &.primary-small-button {
                ${({ theme }) => theme.breakpoints.down("xl")} {
                    width: 545px;
                }

                ${({ theme }) => theme.breakpoints.down("lg")} {
                    width: 545px;

                    &:hover {
                        .button-top-icons .button-left-top-icon {
                            left: -38%;
                            height: 130%;
                            top: -45%;
                        }

                        .button-top-icons .button-right-top-icon {
                            right: -40%;
                            height: 115%;
                        }

                        .button-bottom-icons svg {
                            height: 109%;
                        }

                        .button-bottom-icons .button-blue-icon {
                            visibility: visible;
                            top: 60%;
                            right: -20%;
                        }

                        .button-bottom-icons .button-left-bottom-icon {
                            visibility: visible;
                            top: 34%;
                            left: -32%;
                        }
                    }
                }

                ${({ theme }) => theme.breakpoints.down("s")} {
                    width: 545px;
                }

                &:before {
                    height: 1px;
                }

                &:after {
                    height: 1px;
                }
            }

            &:hover {
                .button-bottom-icons {
                    .button-blue-icon {
                        visibility: visible;
                        top: -20%;
                        right: -20%;
                    }

                    .button-left-bottom-icon {
                        visibility: visible;
                        top: 5%;
                        left: -24%;
                    }
                }

                .button-top-icons {
                    svg {
                        top: -35%;
                    }

                    .button-left-top-icon {
                        left: 6%;
                    }

                    .button-right-top-icon {
                        right: 2%;
                    }
                }
            }

            .container {
                display: flex;
                width: 100%;
            }

            .inm-text-animation {
                position: relative;
                margin: ${({ theme }) => theme.spacing(3)};
                width: 100%;
                text-align: center;
            }

            .clone-item {
                position: absolute;
                inset: 0;
            }

            .default-item span {
                will-change: transform;
                transform-style: preserve-3d;
                transition: 0.5s ease;
                transition-delay: calc(0.05s * var(--index));
                transform-origin: bottom;
                display: inline-block;
            }

            .clone-item span {
                will-change: transform;
                transform-style: preserve-3d;
                transition: 0.5s ease;
                transition-delay: calc(0.05s * var(--index));
                transform-origin: top;
                display: inline-block;
                transform: translateY(100%) rotateX(-90deg);
                font-style: italic;
            }

            ${({ theme }) => theme.breakpoints.down("xxl")} {
                height: 104px;

                .default-item span {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }

                .clone-item span {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }

                .button-bottom-icons {
                    svg {
                        height: 100%;
                    }
                }
            }

            ${({ theme }) => theme.breakpoints.down("xl")} {
                height: 80px;

                .default-item span {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }

                .clone-item span {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }

                .button-bottom-icons {
                    svg {
                        height: 160%;
                    }
                }
            }

            ${({ theme }) => theme.breakpoints.down("lg")} {
                height: 80px;

                .default-item span {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }

                .clone-item span {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }

                .button-bottom-icons {
                    svg {
                        height: 170%;
                    }
                }

                .button-top-icons {
                    svg {
                        width: 100%;
                    }
                }

                .button-top-icons {
                    .button-left-top-icon {
                        left: -45%;
                        height: 130%;
                    }
                }

                .button-top-icons {
                    .button-right-top-icon {
                        right: -50%;
                        height: 90%;
                    }
                }

                &:hover {
                    .button-top-icons {
                        .button-left-top-icon {
                            left: -40%;
                            height: 130%;
                            top: -45%;
                        }
                    }

                    .button-top-icons {
                        .button-right-top-icon {
                            right: -40%;
                            height: 136%;
                        }
                    }
                }
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
                height: 81px;

                .default-item span {
                    font-size: ${({ theme }) => theme.spacing(7)};
                }

                .clone-item span {
                    font-size: ${({ theme }) => theme.spacing(7)};
                }

                .button-bottom-icons {
                    svg {
                        height: 160%;
                    }
                }
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
                height: 74px;

                .button-top-icons {
                    svg {
                        width: 100%;
                    }
                }

                .default-item span {
                    font-size: ${({ theme }) => theme.spacing(5.5)};
                }

                .clone-item span {
                    font-size: ${({ theme }) => theme.spacing(5.5)};
                }

                .button-bottom-icons {
                    svg {
                        height: 126%;
                    }
                }

                &:hover {
                    .button-bottom-icons .button-blue-icon {
                        top: -5%;
                        right: -15%;
                    }

                    .button-right-top-icon {
                        right: -39%;
                        height: 155%;
                    }

                    .button-top-icons .button-left-top-icon {
                        left: -35%;
                        height: 141%;
                        top: -35px;
                    }

                    .button-top-icons .button-right-top-icon {
                        right: -40%;
                        height: 115%;
                        top: -30px;
                    }
                }
            }

            ${({ theme }) => theme.breakpoints.down("sm")} {
                height: 74px;

                .default-item span {
                    font-size: ${({ theme }) => theme.spacing(5.5)};
                }

                .clone-item span {
                    font-size: ${({ theme }) => theme.spacing(5.5)};
                }

                .button-bottom-icons {
                    svg {
                        height: 70px;
                    }
                }

                .button-left-top-icon {
                    height: 88px;
                }

                &:hover {
                    .button-bottom-icons .button-blue-icon {
                        visibility: visible;
                        top: 66%;
                        left: 31%;
                    }

                    .button-left-top-icon {
                        left: -34%;
                        top: -38px;
                    }

                    .button-bottom-icons {
                        .button-left-bottom-icon {
                            visibility: visible;
                            top: 37%;
                            left: -28%;
                        }
                    }
                }
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
                height: 72px;

                .default-item span {
                    font-size: ${({ theme }) => theme.spacing(5)};
                }

                .clone-item span {
                    font-size: ${({ theme }) => theme.spacing(5)};
                }
            }

            &:hover {
                .clone-item span {
                    transform: translate(0) rotateX(0);
                }

                .default-item span {
                    transform: translateY(-100%) rotateX(-90deg);
                }
            }
        `};
    ${({ variant }) =>
        variant === "link" &&
        css`
            background-color: transparent;
            overflow: hidden;
            border: none;

            .link {
                font-size: ${({ theme }) => theme.spacing(10)};
                position: relative;
                white-space: nowrap;
            }

            .link--iocaste {
                font-family: lust-fine, sans-serif;
                overflow: hidden;
                padding: ${({ theme }) => theme.spacing(0.5, 0)};
            }

            .link__graphic {
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                fill: none;
                stroke: ${({ theme }) => theme.palette.primary.main};
                stroke-width: 1px;
            }

            .link__graphic--slide {
                top: -3px;
                stroke-width: 2px;
                transition: transform 0.7s;
                transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
            }

            .link__graphic--slide {
                top: 1px;
                stroke-width: 2px;
                transition: transform 0.7s;
                transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
            }

            svg {
                min-width: 300px;
            }

            label {
                color: ${({ theme }) => theme.palette.primary.main};
            }

            span {
                text-decoration: none;
                display: block;
            }

            &:hover {
                .link__graphic--slide {
                    transform: translate3d(-66.6%, 0, 0);
                }
            }

            ${({ theme }) => theme.breakpoints.down("xl")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("lg")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(6)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(5)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("sm")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(5)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(4.5)};
                }
            }
        `};

    ${({ variant }) =>
        variant === "text" &&
        css`
            border: none;
            background-color: transparent;

            a {
              text-decoration: none;
            }

            .content__item {
              width: 100%;
              height: 100%;
              margin: ${({ theme }) => theme.spacing(0)};
              padding: ${({ theme }) => theme.spacing(0)};
              counter-increment: itemcounter;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: relative;
            }

            .content__item::before {
              color: var(--color-number);
              position: absolute;
              top: 0;
              left: 0;
              content: counters(itemcounter, ".", decimal-leading-zero);
            }

            .link {
              font-size: ${({ theme }) => theme.spacing(4.5)};
              position: relative;
              white-space: nowrap;
              color: var(--color-text);
            }

            .link::before,
            .link::after {
              position: absolute;
              width: 100%;
              height: 1px;
              background: currentColor;
              top: 100%;
              left: 0;
              pointer-events: none;
            }

            .link::before {
              content: '';
            }

            .link--herse {
              font-size: ${({ theme }) => theme.spacing(10)};
              font-weight: bold;
            }

            .link--herse::before {
              display: none;
            }

            .link__graphic {
              position: absolute;
              top: 0;
              left: 0;
              pointer-events: none;
              fill: none;
              stroke: ${({ theme }) => theme.palette.primary.main};
              stroke-width: 1px;
            }

            .link__graphic--stroke path {
              stroke-dasharray: 1;
              stroke-dashoffset: 1;
            }

            .link__graphic--arc {
              top: 73%;
              left: -12%;
            }

            .link__graphic--arc path {
              transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
            }

            span {
              color: ${({ theme }) => theme.palette.primary.main};
            }

            svg {
              width: 65%;
            }

            &:hover {

              .link__graphic--stroke path {
                stroke-dashoffset: 0;
              }

              .link__graphic--arc path {
                transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
                transition-duration: 0.3s;
              }

              svg {
                width: 65%;
              }
            }

            ${({ theme }) => theme.breakpoints.down("xl")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(8)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("lg")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(8)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(6)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(5)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("sm")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(5)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(5)};
                font-weight: bold;
              }
            }
          }
          `};

    ${({ variant }) =>
        variant === "secondary" &&
        css`
            border: none;
            position: relative;

            img {
                animation: ${animationClose} 6.5s linear 0s infinite normal none running;
                width: 280px;
            }

            span {
                position: absolute;
                display: flex;
                transition: all 1s ease-in-out;
                font-size: ${({ theme }) => theme.spacing(16.25)};
                color: ${({ theme }) => theme.palette.primary.main};
                width: 100%;
                top: 105px;
                align-items: center;
                justify-content: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-39deg);
            }

            ${({ theme }) => theme.breakpoints.down("lg")} {
                img {
                    width: 246px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(11.75)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
                img {
                    width: 210px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(10.75)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
                img {
                    width: 154px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(7)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
                img {
                    width: 120px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(4)};
                }
            }

            &:hover {
                span {
                    transform: rotate(0deg);
                    top: ${({ theme }) => theme.spacing(21.25)};
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(0deg);
                }
            }
        `};

    ${({}) =>
        css`
            position: relative;
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            .button-only-icon-vertical {
                color: ${({ theme }) => theme.palette.primary.main};
            }

            .button-only-icon-horizontal {
                position: absolute;
                transform: rotate(90deg);
                color: ${({ theme }) => theme.palette.primary.main};
                display: block;
                transition: all ease 1s;
            }

            .button-only-icon-horizontal {
                color: ${({ theme }) => theme.palette.primary.main};
                rotate: 90deg;
                transform: rotate(90deg);
            }

            &.buttonHover {
                .button-only-icon-horizontal {
                    display: block;
                    fill: ${({ theme }) => theme.palette.primary.main};
                    visibility: hidden;
                    transform: rotate(180deg);
                }
            }
        `};
`;
