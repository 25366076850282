import { TVariants } from "app-design";
import styled from "styled-components";

interface AnimatedTextWrapperProps {
    variant: TVariants;
    isColoredHover?: boolean;
}

export const AnimatedTextWrapper = styled("div")<Partial<AnimatedTextWrapperProps>>`
    cursor: pointer;
    position: relative;
    display: inline-block;
    &:hover {
        .clone-item .${({ variant }) => variant} {
            transform: translate(0) rotateX(0);
            color: ${({ theme, isColoredHover }) => (isColoredHover ? theme.palette.primary.main : "inherit")};
        }
        .default-item .${({ variant }) => variant} {
            transform: translateY(-100%) rotateX(-90deg);
            color: ${({ theme, isColoredHover }) => (isColoredHover ? theme.palette.primary.main : "inherit")};
        }
    }
    .empty-space {
        margin: ${({ theme }) => theme.spacing(0, 1)};
    }
    .default-item {
        display: flex;
    }
    .clone-item {
        position: absolute;
        inset: 0;
        display: flex;
    }
    .default-item .${({ variant }) => variant} {
        user-select: none;
        will-change: transform;
        transform-style: preserve-3d;
        transition: 0.5s ease;
        transition-delay: calc(0.05s * var(--index));
        transform-origin: bottom;
        display: inline-block;
        text-transform: lowercase;
        :first-child {
            text-transform: capitalize;
        }
    }
    .clone-item .${({ variant }) => variant} {
        user-select: none;
        will-change: transform;
        transform-style: preserve-3d;
        transition: 0.5s ease;
        transition-delay: calc(0.05s * var(--index));
        transform-origin: top;
        display: inline-block;
        transform: translateY(100%) rotateX(-90deg);
        font-style: italic;
        text-transform: lowercase;
        :first-child {
            text-transform: capitalize;
        }
    }
`;
