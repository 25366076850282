import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import { AppDecorator } from "../decorators";
import { APP_ROUTES } from "./Routes";

export default function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                {Object.entries(APP_ROUTES).map(([routeKey, route]) => {
                    const { path, Element } = route;
                    return (
                        <Route
                            key={routeKey}
                            path={path}
                            element={
                                <AppDecorator
                                    isFooterEnabled={
                                        routeKey !== "projects" && routeKey !== "project" && routeKey !== "notFound"
                                    }
                                >
                                    <Element />
                                </AppDecorator>
                            }
                        />
                    );
                })}
            </Routes>
        </AnimatePresence>
    );
}
