import styled from "styled-components";

export const ArrowSunWrapper = styled.div`
    position: relative;
    .sun-arrow {
        position: absolute;
        bottom: 120px;
        left: 44px;

        ${({ theme }) => theme.breakpoints.down("xl")} {
            bottom: 0;
            left: 40px;
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            left: 8px;
            bottom: 64px;
        }
        ${({ theme }) => theme.breakpoints.down("s")} {
            left: 8px;
            bottom: 32px;
        }
        ${({ theme }) => theme.breakpoints.down("sm")} {
            left: 12px;
            bottom: 32px;
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            left: 2px;
            bottom: -40px;
        }

        .sun {
            width: 240px;
            height: 240px;
            animation: rotate-right 5s linear infinite;
            color: ${({ theme }) => theme.palette.other.yellow};

            ${({ theme }) => theme.breakpoints.down("xl")} {
                width: 140px;
                height: 140px;
            }
            ${({ theme }) => theme.breakpoints.down("sm")} {
                width: 100px;
                height: 100px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                width: 80px;
                height: 80px;
            }
        }
        .arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 64px;
            height: 64px;
            color: ${({ theme }) => theme.palette.other.yellow};

            ${({ theme }) => theme.breakpoints.down("xl")} {
                width: 32px;
                height: 32px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                width: 24px;
                height: 24px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                width: 18px;
                height: 18px;
            }
        }
    }
`;
