import styled from "styled-components";

export const BackToTopWrapper = styled.div`
    .back-to-top {
        cursor: pointer;
        text-align: right;
        margin-left: auto;
        width: 230px;
        margin-top: ${({ theme }) => theme.spacing(9)};
        ${({ theme }) => theme.breakpoints.down("xl")} {
            margin-top: ${({ theme }) => theme.spacing(7)};
        }
        ${({ theme }) => theme.breakpoints.down("md")} {
            margin-top: ${({ theme }) => theme.spacing(5)};
        }
        ${({ theme }) => theme.breakpoints.down("xs")} {
            margin-top: ${({ theme }) => theme.spacing(4)};
        }
        .show {
            opacity: 1;
        }
        .hide {
            opacity: 0;
        }
        p {
            margin-right: ${({ theme }) => theme.spacing(12)};
            ${({ theme }) => theme.breakpoints.down("xl")} {
                margin-right: ${({ theme }) => theme.spacing(11)};
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                margin-right: ${({ theme }) => theme.spacing(10)};
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                margin-right: ${({ theme }) => theme.spacing(8)};
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                margin-right: ${({ theme }) => theme.spacing(7)};
            }
        }
        .arrow-top-text {
            margin-right: ${({ theme }) => theme.spacing(14)};
            ${({ theme }) => theme.breakpoints.down("xl")} {
                margin-right: ${({ theme }) => theme.spacing(11)};
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                margin-right: ${({ theme }) => theme.spacing(9)};
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                margin-right: ${({ theme }) => theme.spacing(8)};
            }
            &:hover {
                + svg {
                    color: ${({ theme }) => theme.palette.secondary.oldLace};
                }
            }
        }
        svg {
            position: fixed;
            color: ${({ theme }) => theme.palette.secondary.main};
            transition: all ease 0.4s;
            z-index: 1;
            width: 28px;
            height: 28px;
            bottom: 60px;
            right: 56px;
            ${({ theme }) => theme.breakpoints.down("xl")} {
                width: 24px;
                height: 24px;
                bottom: 44px;
                right: 40px;
            }
            ${({ theme }) => theme.breakpoints.down("md")} {
                width: 20px;
                height: 20px;
            }
            ${({ theme }) => theme.breakpoints.down("s")} {
                bottom: 28px;
                right: 24px;
            }
            ${({ theme }) => theme.breakpoints.down("sm")} {
                bottom: 24px;
                right: 20px;
            }
            ${({ theme }) => theme.breakpoints.down("xs")} {
                right: 16px;
                bottom: 200px;
            }
            &:hover {
                color: ${({ theme }) => theme.palette.secondary.oldLace};
            }
        }
    }
`;
